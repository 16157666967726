import { create } from 'zustand';



const aPIURLStore = create((set, get) => ({
    baseURL: '',



    setBaseURL: (value) => set({ baseURL: value })



})
);

export default  aPIURLStore 
