import React from "react";
import { useEffect, useState } from "react";
import useApi from "../utils/UseAPI";
import ContactFormInput from "./ContactFormInput";
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';

const ContactUsForm = () => {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <Button variant="primary" onClick={handleShow}>
                Send Contact Form
            </Button>

            <Offcanvas className="w-50" show={show} onHide={handleClose}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Contact Us</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <p>
                        Please complete the form below and click the Submit button when you are ready to send your question or request.
                    </p>
                    <p>
                        <b>The department cannot guarantee the security of any personally identifying information that you choose to provide</b> in an electronic mail message or over the Internet by electronic means. Please do not include confidential and personal information such as your social security number, date of birth or medical information in this form. You may wish to phone our department if you need to share personally identifying information.
                    </p>
                    <p>
                        <b>Items with bold titles are required.</b> To access an input box directly, hold down the Alt key and press the underlined letter.
                    </p>
                    {/* Input form here:  */}
                    <ContactFormInput />
                </Offcanvas.Body>
            </Offcanvas>

        </>
    )
}
export default ContactUsForm;