import React from "react";
import { useEffect, useState } from "react";
import DepartmentHeader from "../components/DepartmentHeader";
import Navbar from "../layouts/Navbar";
import Breadcrumbs from "../components/buttons/Breadcrumbs";
import Footer from "../layouts/Footer";
import currentPageStore from "../utils/stores/currentPageStore";




function PhoneList({ pageHeader }) {

    const setCurrentPage = currentPageStore((state) => state.setCurrentPage);
    const [setActive, active] = useState('all')
    const tabs = {
        department: [
            "All", "COMM. OFFICE  564-4527", "SUD  564-4456", "DDID  564-7700", "ADM/FIN MGMT  564-4860", "PI", "DMH", "Other"]
    }

    const handlePillClick = (value) => {
        if (value === active) {
            return;
        }
        setActive(value);
    };

    useEffect(() => {
        setCurrentPage(pageHeader);
        // console.log(pageHeader)
    }, [pageHeader]);


    return (


        <>
            <Navbar />
            
            <div className="central-nav-container wrapper ">
                <Breadcrumbs />
                <DepartmentHeader header="DBHDID Central Office Phone Listing" />
                <h4>SUBSTANCE ABUSE HOTLINE <a href="tel:502-287-0632">502-287-0632</a></h4>
                <br />
                <br />
                <br />
                <div className='crisis'>
                    <ul className="nav nav-tabs">
                        {tabs.department.map((tab, index) => {
                            console.log(tab);
                            <li className="nav-item">
                                <a className="nav-link" key={index} onClick={() => handlePillClick(tab)} aria-current="" href="#">{tab}</a>
                            </li>
                        })
                        }
                    </ul>
                </div>

            </div>
            <Footer />
        </>

    )
}



export default PhoneList;