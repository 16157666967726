import React, { useState, useEffect } from 'react';
import useApi from '../utils/UseAPI';
import dataDates from "../data/data.json";
// React loading spinner
import Spinner from 'react-bootstrap/Spinner';


const WIDScrollDown = () => {

    const [selected, setSelected] = useState(null);
    const [content, setContent] = useState([]);
    const { processing, error, getRequest } = useApi();

    useEffect(() => {
        const fetchLinks = async () => {
            const [data, status] = await getRequest(null, "link/792");
            if (status == 200) {
                setContent(data.data.sort((a, b) => a.sort - b.sort));
                // console.log(content)
            }
            else {
                console.log(error);
            }
        };
        fetchLinks();
    }, []);
    return (
        <>
            <div className="col-md-4 col-md-3">
                {/* Return List */}
                <select className="form-select" onChange={(e) => {
                    // console.log(e.target.value);
                    const c = content?.find((x) => x.title === e.target.value)
                    setSelected(c)
                    // console.log(selected)
                }}>
                    <option>Select a date</option>
                    {
                        processing ? <Spinner animation="border" variant="success" /> : dataDates.widDates.map((data, index) => {
                            return (
                                <option key={index} value={data.id} className="form-select"
                                >{data.dates}</option>
                            )
                        })
                    }
                </select>

            </div>
            {selected ? <>
                <br />
                <b>
                    <a href={selected.url}>{selected.text}</a>
                </b>
                <br/>
            </> : null}
        </>
    )
};

export default WIDScrollDown;
