import React from 'react';
import { useState, useEffect } from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import useApi from '../utils/UseAPI';


//React Bootstrap
function ContactFormInput() {

    const [validated, setValidated] = useState(false);
    const { error, postRequest } = useApi();
    const [formError, setFormError] = useState({});
    const [isButtonDisabled, setButtonDisabled] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);

    const [formData, setFormData] = useState({

        pageID: '1',
        firstName: '',
        lastName: '',
        organization: 'n/a',
        address: 'n/a',
        addressTwo: 'n/a',
        city: 'n/a',
        state: 'n/a',
        zip: '00000',
        phone: '000-000-0000',
        fax: '000-000-0000',
        userEmail: '',
        subject: '',
        comment: ''

    })

    const handleSubmit = (e) => {
        disableButton();
        e.preventDefault();
        console.log(formSubmitted);
        const getFormError = findFormErrors(formData);
        // console.log(formData);
        if (Object.keys(getFormError).length > 0) {
            setFormError(getFormError);
            // setTimeout(() => {
            //     enableButton();
            // }, 3000);
        } else {
            postRequest(formData, "email/contact-page");
            setFormSubmitted(true);
        }
    };

    useEffect(() => {
        if (formSubmitted == true) {
            alert("Thank you!","Your form submission has been received.");
            window.location.reload();
        }
    }, [formSubmitted])
    const disableButton = () => {
        setButtonDisabled(true);
    };

    const enableButton = () => {
        setButtonDisabled(false);
    };

    const findFormErrors = (form) => {
        const { firstName, lastName, organization, address, city, state, zip, phone, userEmail, subject, comment } = form
        const message = {}

        if (!firstName || firstName == '') message.firstName = 'Please provide a valid name.'
        else if (firstName.length > 30) message.firstName = 'Please provide a shorter name.'
        if (!lastName || lastName == '') message.lastName = 'Please provide a last name.'
        else if (lastName.length > 30) message.lastName = 'Please provide a shorter last name.'
        if (organization > 30) message.organization = 'Please provide a shorter organization name.'
        if (address.length > 60) message.address = 'Please provide a valid address.'
        // if (addressTwo.length > 60) message.addressTwo = 'Please provide a valid address.'
        // if (!city || city.length == '') message.city = 'Please provide a valid city.'
        else if (city.length > 30) message.city = 'Please provide a shorter city name.'
        // if (!state || state == '') message.state = 'Please provide a valid state.'
        if (state.length > 30) message.state = 'Please provide a valid state.'
        if (zip.length > 5 || zip.length < 5) message.zip = 'Please provide a valid zip code. (5 digits)'
        // if (!phone || phone == '') message.phone = 'Please provide a valid phone number.'
        // else if (phone.length > 10) message.phone = 'Please provide a valid Phone number.'
        if (!subject || subject == "") message.subject = 'Please provide a subject name.'
        else if (subject.length > 15) message.phone = 'Please provide a subject under 15 characters.'
        if (!userEmail || userEmail == "") message.userEmail = 'Please provide a valid email address.'
        // if ( userEmail == "") message.userEmail = 'Please provide a valid email address.'
        if (!comment || comment == '') message.comment = 'Please provide a reason.'
        else if (comment.length > 500) message.phone = 'Please provide a reason.'


        // console.log(message)

        return message
    }

    return (
        <>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row className="mb-4">
                    <Form.Group as={Col} md="10" controlId="validationCustom01">
                        <Form.Label><b>*First Name:</b></Form.Label>
                        <Form.Control
                            required
                            type='text'
                            placeholder="First Name"
                            aria-describedby="inputGroupPrepend"
                            onChange={(e) => { setFormData({ ...formData, firstName: e.target.value }) }}
                            isInvalid={!!formError.firstName}
                        />
                        <Form.Control.Feedback type='invalid'>{formError.firstName}</Form.Control.Feedback>

                    </Form.Group>
                    <Form.Group as={Col} md="10" controlId="validationCustom02">
                        <Form.Label><b>*Last Name:</b></Form.Label>
                        <Form.Control
                            required
                            type="text"
                            placeholder="Last Name"
                            aria-describedby="inputGroupPrepend"
                            defaultValue=""
                            isInvalid={!!formError.lastName}
                            onChange={(e) => { setFormData({ ...formData, lastName: e.target.value }) }}
                        />

                        <Form.Control.Feedback type='invalid'>{formError.lastName}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="10" controlId="validationCustom03">
                        <Form.Label><b>Organization:</b></Form.Label>

                        {/* <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text> */}
                        <Form.Control
                            type="text"
                            placeholder="Organization"
                            aria-describedby="inputGroupPrepend"
                            isInvalid={!!formError.organization}
                            // required

                            onChange={(e) => { e.target.value == '' || e.target.value === null ? setFormData({ ...formData, organization: "n/a" }) : setFormData({ ...formData, organization: e.target.value }) }}
                        />
                        <Form.Control.Feedback type='invalid'>{formError.organization}</Form.Control.Feedback>

                    </Form.Group>
                    <Form.Group as={Col} md="10" controlId="validationCustom04">
                        <Form.Label><b>Address:</b></Form.Label>

                        {/* <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text> */}
                        <Form.Control
                            type="text"
                            placeholder="Address"

                            isInvalid={!!formError.address}
                            aria-describedby="inputGroupPrepend"

                            onChange={(e) => { setFormData({ ...formData, address: e.target.value }) }}
                        />
                        <Form.Control.Feedback type='invalid'>{formError.address}</Form.Control.Feedback>

                    </Form.Group>
                    <Form.Group as={Col} md="10" controlId="validationCustom05">
                        <Form.Label><b>Address 2:</b></Form.Label>

                        {/* <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text> */}
                        <Form.Control
                            type="text"
                            placeholder="Address Two"

                            isInvalid={!!formError.addressTwo}
                            aria-describedby="inputGroupPrepend"

                            onChange={(e) => { setFormData({ ...formData, addressTwo: e.target.value }) }}
                        />
                        <Form.Control.Feedback type='invalid'>{formError.addressTwo}</Form.Control.Feedback>

                    </Form.Group>
                </Row>
                <Row className="mb-4">
                    <Form.Group as={Col} md="10" controlId="validationCustom06">
                        <Form.Label><b>City:</b></Form.Label>
                        <Form.Control type="text" placeholder="City" isInvalid={!!formError.city}
                            onChange={(e) => { setFormData({ ...formData, city: e.target.value }) }}
                        />

                        <Form.Control.Feedback type='invalid'>{formError.city}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="10" controlId="validationCustom07">
                        <Form.Label><b>State:</b></Form.Label>
                        <Form.Control type="text" placeholder="State" isInvalid={!!formError.state}
                            onChange={(e) => { setFormData({ ...formData, state: e.target.value }) }} />
                        <Form.Control.Feedback type='invalid'>{formError.state}</Form.Control.Feedback>

                    </Form.Group>
                    <Form.Group as={Col} md="10" controlId="validationCustom08">
                        <Form.Label><b>Zip Code:</b></Form.Label>
                        <Form.Control type="text" placeholder="Zip Code" isInvalid={!!formError.zip}
                            minLength={5}

                            onChange={(e) => { setFormData({ ...formData, zip: e.target.value }) }}
                        />
                        <Form.Control.Feedback type='invalid'>{formError.zip}</Form.Control.Feedback>

                    </Form.Group>
                    <Form.Group as={Col} md="10" controlId="validationCustom09">
                        <Form.Label><b>Phone:</b></Form.Label>
                        <Form.Control type="text" placeholder="XXX-XXX-XXXX format"
                            isInvalid={!!formError.phone}
                            onChange={(e) => { setFormData({ ...formData, phone: e.target.value }) }} />
                        <Form.Control.Feedback type='invalid'>{formError.phone}</Form.Control.Feedback>

                    </Form.Group>
                    {/* <Form.Group as={Col} md="10" controlId="validationCustom08">
                        <Form.Label><b>Fax:</b></Form.Label>
                        <Form.Control type="text" placeholder="XXX-XXX-XXXX format"
                            onChange={(e) => { setFormData({ ...formData, fax: e.target.value }) }}
                        />
                        <Form.Control.Feedback > */}
                    {/* Please provide a valid Phone number */}
                    {/* </Form.Control.Feedback>
                    </Form.Group> */}
                    <Form.Group as={Col} md="10" controlId="validationCustom10">
                        <Form.Label><b>*Email:</b></Form.Label>
                        <Form.Control  type="text" placeholder="Email" required isInvalid={!!formError.userEmail}
                            onChange={(e) => { setFormData({ ...formData, userEmail: e.target.value }) }}
                        />
                        <Form.Control.Feedback type='invalid'>{formError.userEmail}</Form.Control.Feedback>

                    </Form.Group>
                    <Form.Group as={Col} md="10" controlId="validationCustom11">
                        <Form.Label><b>*Subject:</b></Form.Label>
                        <Form.Control type="text" placeholder="Subject" required isInvalid={!!formError.subject}
                            onChange={(e) => { setFormData({ ...formData, subject: e.target.value }) }} />
                        <Form.Control.Feedback type='invalid'>{formError.subject}</Form.Control.Feedback>

                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                        <Form.Label><b>*Comment:</b></Form.Label>
                        <Form.Control as="textarea" required rows={3} isInvalid={!!formError.comment}
                            onChange={(e) => { setFormData({ ...formData, comment: e.target.value }) }}
                        />
                        <Form.Control.Feedback type='invalid'>{formError.comment}</Form.Control.Feedback>
                    </Form.Group>
                </Row>
                <Form.Group className="mb-3">
                    <Form.Check
                        required
                        label="Agree to terms and conditions"
                        feedback="You must agree before submitting."
                        feedbackType="invalid"
                    />
                </Form.Group>
                <Button
                    type="submit">Submit</Button>
            </Form>
        </>
    );
}

export default ContactFormInput;