// lib use to switch between pages
import { Routes, Route } from "react-router-dom";
import React from "react";
// Pages:
// External/Other Pages
import Crisisnos from "./pages/crisisnos";
import Home from "./pages/Home";
import Calendar from "./pages/Calendar"
import ProviderDirectory from "./providerDirectory/ProviderDirectory";
// AFM:
import aFM_Content from "./pages/afm/Content/aFM_Content";
// Error Page:
import Error from "./pages/Error";
import Page from "./layouts/Page";
//Page Content
import kIPContent from "./pages/sud/content/kIP_Content";
import sAKPNContent from "./pages/sud/content/sAKPN_Content";
import pESContent from "./pages/sud/content/pES_Content";
import yEGContent from "./pages/sud/content/yEG_Content";
import kRSPMGContent from "./pages/sud/content/kRSPMG_Content";
import continuumContent from "./pages/sud/content/continuum_Content";
import childrenContent from "./pages/mh/Content/children_Content";
import adolescentContent from "./pages/mh/Content/adolescent_Content";
import eCMH_Content from "./pages/mh/Content/eCMH_Content";
import eCMHLinks_Content from "./pages/mh/Content/eCMHLinks_Content";
import hFW_Content from "./pages/mh/Content/hFW_Content";
import kYImpact_Content from "./pages/mh/Content/kYImpact_Content";
import rIAC_Content from "./pages/mh/Content/rIAC_Content";
import cCC_Content from "./pages/mh/Content/cCC_Content";
import sIAC_Content from "./pages/mh/Content/sIAC_Content";
import systemOfCare_Content from "./pages/mh/Content/systemOfCare_Content";
import tCMChild_Content from "./pages/mh/Content/tCMChild_Content";
import youthAdult_Content from "./pages/mh/Content/youthAdult_Content";
// import tIC_Content from "./pages/mh/Content/tIC_Content";
import kEDC_Content from "./pages/mh/Content/kEDC_Content";
import pPP_Content from "./pages/mh/Content/pPP_Content";
import mHPromotion_Content from "./pages/mh/Content/mHPromotion_Content";
import sP_Content from "./pages/mh/Content/sP_Content";
import kCCRT_Content from "./pages/mh/Content/kCCRT_Content";
import dataGuide_Content from "./pages/cmhc/Content.js/dataGuide_Content";
import traumaAndResilience_Content from "./pages/Content/traumaAndResilience_Content";
// import TestPage from "./pages/TestPage";
// import ContactInfo from "./components/ContactInfo";
// import findID from "./components/GrabID";
import dHHS_Content from "./pages/mh/Content/dHHS_Content";
import adultSA_Content from "./pages/mh/Content/adultSA_Content";
import coocurring_Content from "./pages/mh/Content/cooccurring_Content";
import adultMHCJI_Content from "./pages/mh/Content/adultMHCJI_content";

import jCIC_Content from "./pages/cmhc/Content.js/jCIC_Content";
import eBPIInterviewing from "./pages/sud/content/eBPIInterviewing_Content";
import eBPINiatx from "./pages/sud/content/eBPINiatx_Content";
import ResourcesContent from "./pages/sud/content/resources_Content";
import wID_Content from "./pages/Content/wID_Content";
import EvidenceBasedInitiativesContent from "./pages/sud/content/evidenceBasedInitiatives_Content";
import TrustContent from "./pages/sud/content/trust_Content";
import CBHTContent from "./pages/mh/Content/cBHT_Content";
import BHPACContent from "./pages/mh/Content/bHPAC_Content";
import BHPACAboutContent from "./pages/mh/Content/bHPACAbout_Content";
import immersionContent from "./pages/sud/content/immersion_Content";
import BHPACSummariesContent from "./pages/mh/Content/bHPACSummaries_Content";
import BHPACBGContent from "./pages/mh/Content/bHPACBG_Content";
import sOCA_Content from "./pages/mh/Content/sOCA_Content";
import impactVideo_Content from "./pages/mh/Content/impactVideo_Content";
import actPrinciplesContent from "./pages/mh/Content/actPrinciples_Content";
import traumaAndTerrorismContent from "./pages/Content/trauma_Content";
import guidePrinciplesContent from "./pages/Content/guidePrinciples_Content";
import hIPPAALawContent from "./pages/Content/hIPAALaw_Content";
import recordingsContent from "./pages/ddid/Content/recordings_Content";
import BestPracticeContent from "./pages/sud/content/bestPractice_Content";
import dataCommitteeContent from "./pages/cmhc/Content.js/dataCommittee_Content";
import sIACSummaries_Content from "./pages/mh/Content/sIACSummaries_Content";
import kYCCL_Content from "./pages/Content/kYCCL_Content";
import pASSRR_Content from "./pages/Content/pASRR_Content";
import pASSRRForms_Content from "./pages/Content/pASRRForms_Content";
import crisis_Content from "./pages/Content/crisis_Content";
import cMHCHome_Content from "./pages/cmhc/Content.js/cMHCHome_Content";
import dataReports_Content from "./pages/cmhc/Content.js/dataReports_Content";
import centers_Content from "./pages/cmhc/Content.js/centers_Content";
import cRD_Content from "./pages/cmhc/Content.js/cRD_Content";
import planAndBudget_Content from "./pages/cmhc/Content.js/planAndBudget_Content";
import qMOTSurveys_Content from "./pages/cmhc/Content.js/qMOTSurveys_Content";
import qMOTDocs_Content from "./pages/cmhc/Content.js/qMOTDocs_Content";
import pI_Content from "./pages/cmhc/Content.js/pI_Content.";
import dPR_Content from "./pages/cmhc/Content.js/dPR_Content";
import dataInfo_Content from "./pages/cmhc/Content.js/dataInfo_Content";
import sUDHome_Content from "./pages/sud/content/sUDHome_Content";
import eBPIRecovery_Content from "./pages/sud/content/eBPIRecovery_Content";
import sMVF_Content from "./pages/sud/content/sMVF_Content";
import headedHome_Content from "./pages/sud/content/headedHome_Content";
import aSAMPPC_Content from "./pages/sud/content/aSAMPPC_Content";
import faithBased_Content from "./pages/sud/content/faithBased_Content";
import kYMomsMATR_Content from "./pages/sud/content/kYMomsMATR_Content";
import oxfordHouse_Content from "./pages/sud/content/oxfordHouse_Content";
import kRHN_Content from "./pages/sud/content/kRHN_Content";
import women_Content from "./pages/sud/content/women_Content";
import bHPP_Content from "./pages/sud/content/bHPP_Content";
import sARPC_Content from "./pages/sud/content/sARPC_Content";
import wDYE_Content from "./pages/sud/content/wDYE_Content";
import sSPW_Content from "./pages/sud/content/sSPW_Content";
import strength_Content from "./pages/sud/content/strength_Content";
import tGD_Content from "./pages/sud/content/tGD_Content";
import pIP_Content from "./pages/sud/content/pIP_Content";
import dTP_Content from "./pages/sud/content/dTP_Content";
import kSAP_Content from "./pages/sud/content/kSAP_Content";
import synar_Content from "./pages/sud/content/synar_Content";
import zero_Content from "./pages/sud/content/zero_Content";
import mHHome_Content from "./pages/mh/Content/mHHome_Content";
import dDIDHome_Content from "./pages/ddid/Content/dDIDHome_Content";
import cSA_Content from "./pages/mh/Content/cSA_Content";
import pSS_Content from "./pages/mh/Content/pSS_Content";
import tCM_Content from "./pages/mh/Content/tCM_Content";
import eBPCYF_Content from "./pages/mh/Content/eBPCYF_Content";
import adultMH_Content from "./pages/mh/Content/adultMH_Content";
import act_Content from "./pages/mh/Content/act_Content";
import employment_Content from "./pages/mh/Content/employment_Content";
import housing_Content from "./pages/mh/Content/housing_Content";
import tCMAdult_Content from "./pages/mh/Content/tCMAdult_Content";
import housingResources_Content from "./pages/mh/Content/housingResources_Content";
import adultMHAging_Content from "./pages/mh/Content/adultMHAging_Content";
import dataReportsFacility_Content from "./pages/facilites/Content/dataReport_Content";
import facilitesHome_Content from "./pages/facilites/Content/FaciliitesHome_Content";
import pDRates_Content from "./pages/facilites/Content/pDRates_Content";
import procedures_Content from "./pages/facilites/Content/procedures_Content";
import dPIHome_Content from "./pages/dpi/Content/dPIHome_Content";
import dUI_Content from "./pages/dpi/Content/dUI_Content";
import opiate_Content from "./pages/dpi/Content/opiate_Content";
import cAP_Content from "./pages/dpi/Content/cAP_Content";
import tRIS_Content from "./pages/dpi/Content/tRIS_Content";
import dUITraining_Content from "./pages/dpi/Content/dUITraining_Content";
import dUIForms_Content from "./pages/dpi/Content/dUIForms";
import dUIFAQ_Content from "./pages/dpi/Content/dUIFAQ";
import cSB_Content from "./pages/ddid/Content/cSB_Content";
import sCL_Content from "./pages/ddid/Content/sCL_Content";
import clinical_Content from "./pages/ddid/Content/clinical_Content";
import sCLTraining_Content from "./pages/ddid/Content/sCLTraining";
import sCLNewProvider_Content from "./pages/ddid/Content/sCLNewProvider";
import hRCBIC_Content from "./pages/ddid/Content/hRCBIC_Content";
import cMTraining_Content from "./pages/ddid/Content/cMTraining_Content";
import commission_Content from "./pages/ddid/Content/commission_Content";
import commissionDocuments_Content from "./pages/ddid/Content/commissionDocuments_Content";
import sCLFormsCertification_Content from "./pages/ddid/Content/sCLFormsCertification_Content";
import sCLFormsCM_Content from "./pages/ddid/Content/sCLFormsCM_Content";
import sCLFormsEmployment_Content from "./pages/ddid/Content/sCLFormsEmployment_Content";
import sCLFormsGeneral_Content from "./pages/ddid/Content/sCLFormsGeneral_Content";
import sCLFormsIncident_Content from "./pages/ddid/Content/sCLFormsIncident_Content";
import sCLFormsRisk_Content from "./pages/ddid/Content/sCLFormsRisk";
import sCLForms_Content from "./pages/ddid/Content/sCLForms_Content";
import sCLQASubmit_Content from "./pages/ddid/Content/sCLQASubmit_Content";
import sCLRegulations_Content from "./pages/ddid/Content/sCLRegulations_Content";
import sCLServices_Content from "./pages/ddid/Content/sCLServices_Content";
import sGFForms_Content from "./pages/ddid/Content/sGFForms_Content";
import iM_Content from "./pages/ddid/Content/iM_Content";
import olmstead_Content from "./pages/Content/olmstead_Content";
import hOTLINES_Content from "./pages/Content/hOTLINES_Content";
import autism_Content from "./pages/Content/autism_Content";
import aPIURLStore from "./utils/stores/aPIURLStore";
import dPRForms_Content from "./pages/cmhc/Content.js/dPRForms_Content";
import pBForms_Content from "./pages/cmhc/Content.js/pBFormsLibrary_Content";
import pBFormsLibrary_Content from "./pages/cmhc/Content.js/pBFormsLibrary_Content";
import dPRFormsLibrary_Content from "./pages/cmhc/dPRFormsLibrary_Content";
import PhoneList from "./pages/PhoneList";
import aOTContent from "./pages/mh/Content/aOT_Content";
import eBPIIntegrated_Content from "./pages/mh/Content/eBPIIntegrated_Content";

const App = () => {
  const setBaseURL = aPIURLStore(state => state.setBaseURL);
  const currentLocation = window.location.href
  if (currentLocation.includes("dbhdid.ky")) {
    setBaseURL("https://dbhdid.ky.gov/api/");
  }
  else {
    setBaseURL("https://dbhdidtest.ky.gov/api/");
  }
  return (
    <>
      <Routes >
        {/* // ================================ Body =================================================== */}
        {/* Base on the database, some pages don't have a pageID.*/}
        {/* External Links */}
        <Route path="/" element={<Home />} />
        <Route path="/calendar" element={<Calendar pageHeader={"Calendar of Events"} />} />
        <Route path="/providerDirectory" element={<ProviderDirectory />} />
        {/* <Route path="/index" element={<Index />} /> */}
        {/* <Route path="/contact" element={<Contact />} /> */}
        <Route path="/crisisnos" element={<Crisisnos pageHeader={"County Contacts"} />} />
        <Route path="/pasrr" element={<Page iD={26} pageHeader={"Preadmission Screening and Resident Review"} content={pASSRR_Content} />} />
        <Route path="/pasrr-forms" element={<Page iD={26} pageHeader={"Preadmission Screening and Resident Review"} content={pASSRRForms_Content} />} />
        <Route path="/crisis" element={<Page iD={428} pageHeader={"Crisis Prevention and Response System"} content={crisis_Content} />} />
        <Route path="/hotlines" element={<Page iD={457} pageHeader={"Hotlines and Other Contacts"} content={hOTLINES_Content} />} />
        <Route path="/tr" element={<Page iD={503} pageHeader={"Trauma, Resilience & Trauma-Informed Care"} content={traumaAndResilience_Content} />} />
        {/* AFM */}
        <Route path="/afm" element={<Page iD={228} pageHeader={"Administration and Financial Management"} content={aFM_Content} />} />
        <Route path="/olmstead" element={<Page iD={74} pageHeader={"Olmstead Planning"} content={olmstead_Content} />} />
        <Route path="/wid" element={<Page iD={510} pageHeader={"The Workforce Innovation and Development (WID) Collaborative"} content={wID_Content} />} />
        <Route path="/trauma" element={<Page iD={5} pageHeader={"Department for Behavioral Health, Developmental and Intellectual Disabilities"} content={traumaAndTerrorismContent} />} />
        <Route path="/gp" element={<Page iD={68} pageHeader={"Department for Behavioral Health, Developmental and Intellectual Disabilities"} content={guidePrinciplesContent} />} />
        <Route path="/hipaa-law" element={<Page iD={70} pageHeader={"Health Insurance Portability and Accountability Act"} content={hIPPAALawContent} />} />
        <Route path="/ky-ccl" element={<Page iD={508} pageHeader={"Kentucky Civil Commitment Laws"} content={kYCCL_Content} />} />
        <Route path="/autism" element={<Page iD={436} pageHeader={"Kentucky Advisory Council on Autism & Office of Autism"} content={autism_Content} />} />
        {/* <Route path="/phonelist" element={<PhoneList pageHeader={"DBHDID Central Office Phone Listing"} />} /> */}
        {/* CMHC */}
        <Route path="/cmhc" element={<Page iD={229} pageHeader={"Community Mental Health Centers"} content={cMHCHome_Content} />} />
        <Route path="/cmhc/datareports" element={<Page iD={446} pageHeader={"Basic Statistical Reports"} content={dataReports_Content} />} />
        <Route path="/cmhc/centers" element={<Page iD={322} pageHeader={"Center Information"} content={centers_Content} />} />
        <Route path="/cmhc/crd" element={<Page iD={427} pageHeader={"CMHC Contract Reference Documents"} content={cRD_Content} />} />
        <Route path="/cmhc/plan-and-budget" element={<Page iD={321} pageHeader={"Plan and Budget Information - FY 2025"} content={planAndBudget_Content} />} />
        <Route path="/cmhc/qmot-surveys" element={<Page iD={318} pageHeader={"Quality Management and Outcomes Team "} content={qMOTSurveys_Content} />} />
        <Route path="/cmhc/qmot-docs" element={<Page iD={316} pageHeader={"Quality Management and Outcomes Team Documents"} content={qMOTDocs_Content} />} />
        {/* <Route path="/cmhc/qmot" element={<QMOT />} /> */}
        <Route path="/cmhc/pi" element={<Page iD={453} pageHeader={"Performance Indicator Implementation Guides"} content={pI_Content} />} />
        <Route path="/cmhc/dpr" element={<Page iD={6} pageHeader={"Department Periodic Reports Information - FY 2025"} content={dPR_Content} />} />
        {/* <Route path="/cmhc/dpr-info" element={<Page iD={432} pageHeader={"Department Periodic Reports Forms"} content={dPRForms_Content} />} /> */}
        <Route path="/cmhc/datainfo" element={<Page iD={233} pageHeader={"Data Information"} content={dataInfo_Content} />} />
        <Route path="/cmhc/dataguide" element={<Page iD={448} pageHeader={"CMHC Data Guides and Documentation"} content={dataGuide_Content} />} />
        {/* <Route path="/cmhc/jcic" element={<Page iD={314} pageHeader={"Joint Committee on Information Continuity"} content={jCIC_Content} />} /> */}
        <Route path="/cmhc/data-committees" element={<Page iD={508} pageHeader={"Data Committees"} content={dataCommitteeContent} />} />
        <Route path="/cmhc/pb-forms" element={<Page iD={323} pageHeader={"Plan and Budget Forms Library"} content={pBFormsLibrary_Content} />} />
        <Route path="/cmhc/dpr-forms" element={<Page iD={432} pageHeader={"Department Periodic Reports Forms Library"} content={dPRFormsLibrary_Content} />} />
        {/* // ===================================================================================================================== */}
        {/* // ================================ SUD: Substance Use Disorder =================================================== */}
        {/* SUD */}
        <Route path="/sud" element={<Page iD={485} pageHeader={"Substance Use Disorder"} content={sUDHome_Content} />} />
        <Route path="/sud/ebpi-recovery" element={<Page iD={245} pageHeader={"Recovery and Peer Support"} content={eBPIRecovery_Content} />} />
        <Route path="/sud/smvf" element={<Page iD={424} pageHeader={"Service Members, Veterans and Their Families Behavioral Health Initiative"} content={sMVF_Content} />} />
        <Route path="/sud/headedhome" element={<Page iD={425} pageHeader={"Operation Headed Home"} content={headedHome_Content} />} />
        <Route path="/sud/asamppc" element={<Page iD={268} pageHeader={"Adult Substance Abuse Treatment and Recovery Services Branch"} content={aSAMPPC_Content} />} />
        <Route path="/sud/faithbased" element={<Page iD={92} pageHeader={"Faith-Based Community Support"} content={faithBased_Content} />} />
        <Route path="/sud/kymomsmatr" element={<Page iD={128} pageHeader={"KY Moms Maternal Assistance Towards Recovery (MATR)"} content={kYMomsMATR_Content} />} />
        <Route path="/sud/oxfordhouse" element={<Page iD={405} pageHeader={"Oxford House of Kentucky"} content={oxfordHouse_Content} />} />
        <Route path="/sud/krhn" element={<Page iD={472} pageHeader={"Recovery Housing and Transitional Living"} content={kRHN_Content} />} />
        <Route path="/sud/women" element={<Page iD={38} pageHeader={"Women's Substance Use Treatment"} content={women_Content} />} />
        <Route path="/sud/bhpp" element={<Page iD={106} pageHeader={"Substance Use Prevention & Promotion Branch"} content={bHPP_Content} />} />
        <Route path="/sud/sa-rpc" element={<Page iD={208} pageHeader={"Regional Prevention Centers (RPCs)"} content={sARPC_Content} />} />
        <Route path="/sud/wdye" element={<Page iD={486} pageHeader={"Workforce Development and Youth Empowerment"} content={wDYE_Content} />} />
        <Route path="/sud/sspw" element={<Page iD={487} pageHeader={"Supporting a Strong Prevention Workforce"} content={sSPW_Content} />} />
        <Route path="/sud/strength" element={<Page iD={488} pageHeader={"Sources of Strength"} content={strength_Content} />} />
        <Route path="/sud/tgd" element={<Page iD={489} pageHeader={"Too Good for Drugs"} content={tGD_Content} />} />
        <Route path="/sud/pip" element={<Page iD={490} pageHeader={"Partners in Prevention (PIP)"} content={pIP_Content} />} />
        <Route path="/sud/dtp" element={<Page iD={491} pageHeader={"The Dinner Table Project"} content={dTP_Content} />} />
        <Route path="/sud/kpsap" element={<Page iD={492} pageHeader={"The Kentucky Purple Star Award Program"} content={kSAP_Content} />} />
        <Route path="/sud/synar" element={<Page iD={493} pageHeader={"Synar Program"} content={synar_Content} />} />
        <Route path="/sud/zero" element={<Page iD={494} pageHeader={"Zero Tolerance"} content={zero_Content} />} />
        <Route path="/sud/kip" element={<Page iD={192} pageHeader={"Kentucky Incentives for Prevention (KIP) Survey"} content={kIPContent} />} />
        <Route path="/sud/sa-kpn" element={<Page iD={275} pageHeader={"The Kentucky Prevention Network (KPN)"} content={sAKPNContent} />} />
        <Route path="/sud/sa-pes" element={<Page iD={196} pageHeader={"Prevention Enhancement System"} content={pESContent} />} />
        <Route path="/sud/yeg" element={<Page iD={495} pageHeader={"Youth Empowerment Grant"} content={yEGContent} />} />
        <Route path="/sud/krspmg" element={<Page iD={496} pageHeader={"Kentucky Retail Survey Project (Nicotine & THC) Mini Grant"} content={kRSPMGContent} />} />
        {/* Don't have a page ID */}
        <Route path="/sud/continuum" element={<Page iD={497} pageHeader={"Promoting Collaboration through the Behavioral Health Continuum of Care"} content={continuumContent} />} />
        <Route path="/sud/ebpi-interviewing" element={<Page iD={248} pageHeader={"Evidence-Based Practice Initiatives"} content={eBPIInterviewing} />} />
        <Route path="/sud/ebpi-niatx" element={<Page iD={250} pageHeader={"Evidence-Based Practice Initiatives"} content={eBPINiatx} />} />
        <Route path="/sud/resources" element={<Page iD={485} pageHeader={"Resources"} content={ResourcesContent} />} />
        {/* Don't have a page ID */}
        <Route path="/sud/ebpi-general" element={<Page iD={504} pageHeader={"Evidence-Based Initiatives "} content={EvidenceBasedInitiativesContent} />} />
        <Route path="/sud/sa-trust" element={<Page iD={286} pageHeader={"Tobacco Retailer Underage Sales Training (TRUST)"} content={TrustContent} />} />
        <Route path="/sud/immersion" element={<Page iD={426} pageHeader={"Operation Immersion "} content={immersionContent} />} />
        <Route path="/sud/bestpractice" element={<Page iD={242} pageHeader={"Behavioral Health Best Practices "} content={BestPracticeContent} />} />
        <Route path="/sud/ebpi-integrated" element={<Page iD={244} pageHeader={"DDCAT and DDCMHT Integrated Services Initiative"} content={eBPIIntegrated_Content} />} />
        {/* // ===================================================================================================================== */}
        {/* // ================================ MH: Division of Mental Health  =================================================== */}
        {/* MH */}
        <Route path="/mh" element={<Page iD={484} pageHeader={"Mental Health"} content={mHHome_Content} />} />
        <Route path="/mh/csa" element={<Page iD={396} pageHeader={"Community Support Associate Curriculum Approval Process"} content={cSA_Content} />} />
        <Route path="/mh/pss" element={<Page iD={411} pageHeader={"Peer Support Specialist Curriculum Approval Process"} content={pSS_Content} />} />
        <Route path="/mh/tcm" element={<Page iD={407} pageHeader={"Targeted Case Management Curriculum Approval Process"} content={tCM_Content} />} />
        <Route path="/mh/ebpcyf" element={<Page iD={453} pageHeader={"Evidence-based and Best Practices for Children, Youth, and Families"} content={eBPCYF_Content} />} />
        <Route path="/mh/adultmh" element={<Page iD={14} pageHeader={"Adult Mental Health Branch"} content={adultMH_Content} />} />
        <Route path="/mh/act" element={<Page iD={47} pageHeader={"ACT Principles"} content={act_Content} />} />
        <Route path="/mh/employment" element={<Page iD={159} pageHeader={"Supported Employment"} content={employment_Content} />} />
        <Route path="/mh/housing" element={<Page iD={113} pageHeader={"Housing and Homeless Programs"} content={housing_Content} />} />
        <Route path="/mh/tcm-adult" element={<Page iD={157} pageHeader={"Adult Mental Health and Recovery Services"} content={tCMAdult_Content} />} />
        <Route path="/mh/housing-resources" element={<Page iD={115} pageHeader={"Housing and Homeless Programs"} content={housingResources_Content} />} />
        <Route path="/mh/adultmh-aging" element={<Page iD={442} pageHeader={"Kentucky Mental Health and Aging Coalition"} content={adultMHAging_Content} />} />
        <Route path="/mh/children" element={<Page iD={14} pageHeader={"Children’s Mental Health and Recovery Services Branch"} content={childrenContent} />} />
        <Route path="/mh/adolescent" element={<Page iD={422} pageHeader={"Youth Substance Use Treatment"} content={adolescentContent} />} />
        <Route path="/mh/ecmh" element={<Page iD={106} pageHeader={"Early Childhood Mental Health Program"} content={eCMH_Content} />} />
        <Route path="/mh/ecmh-links" element={<Page iD={111} pageHeader={"Early Childhood Mental Health Program (Links)"} content={eCMHLinks_Content} />} />
        <Route path="mh/hfw" element={<Page iD={420} pageHeader={"High Fidelity Wraparound"} content={hFW_Content} />} />
        <Route path="mh/kyimpact" element={<Page iD={144} pageHeader={"Kentucky IMPACT"} content={kYImpact_Content} />} />
        <Route path="mh/riac" element={<Page iD={473} pageHeader={"Regional Interagency Councils"} content={rIAC_Content} />} />
        <Route path="mh/ccc" element={<Page iD={338} pageHeader={"Commissions, Councils and Committees"} content={cCC_Content} />} />
        <Route path="mh/siac" element={<Page iD={37} pageHeader={"State Interagency Council for Services and Supports to Children and Transition-age Youth"} content={sIAC_Content} />} />
        <Route path="mh/siac-summaries" element={<Page iD={374} pageHeader={"State Interagency Council"} content={sIACSummaries_Content} />} />
        <Route path="mh/systemofcare" element={<Page iD={18} pageHeader={"System of Care Expansion and Sustainability"} content={systemOfCare_Content} />} />
        <Route path="mh/tcm-child" element={<Page iD={32} pageHeader={"Targeted Case Management Services for Children/Youth with Severe Emotional Disabilities"} content={tCMChild_Content} />} />
        <Route path="mh/tcm-adult" element={<Page iD={157} pageHeader={"Transition -Age Youth and Young Adults"} content={tCMChild_Content} />} />
        <Route path="mh/youth-adult" element={<Page iD={19} pageHeader={"Transition -Age Youth and Young Adults"} content={youthAdult_Content} />} />
        {/* <Route path="mh/tic" element={<Page iD={249} pageHeader={"Trauma-Informed Care"} content={tIC_Content} />} /> */}
        <Route path="mh/kedc" element={<Page iD={476} pageHeader={"Kentucky Eating Disorder Council"} content={kEDC_Content} />} />
        <Route path="mh/ppp" element={<Page iD={486} pageHeader={"Promotion, Prevention, and Preparedness"} content={pPP_Content} />} />
        <Route path="mh/mhp" element={<Page iD={499} pageHeader={"Mental Health Promotion"} content={mHPromotion_Content} />} />
        <Route path="mh/sp" element={<Page iD={16} pageHeader={"Suicide Prevention Program"} content={sP_Content} />} />
        <Route path="mh/kccrt" element={<Page iD={501} pageHeader={"Kentucky Community Crisis Response Team "} content={kCCRT_Content} />} />
        <Route path="/mh/dhhs" element={<Page iD={43} pageHeader={"Deaf and Hard of Hearing Services"} content={dHHS_Content} />} />
        <Route path="/mh/adultsa" element={<Page iD={160} pageHeader={"Adult Substance Use Treatment and Recovery Branch"} content={adultSA_Content} />} />
        <Route path="/mh/cooccurring" element={<Page iD={251} pageHeader={"Co-Occurring Disorders"} content={coocurring_Content} />} />
        <Route path="/mh/adultmh-cji" element={<Page iD={443} pageHeader={"Adult Mental Health and Recovery Services Branch"} content={adultMHCJI_Content} />} />
        <Route path="/mh/cbht" element={<Page iD={475} pageHeader={"Community Behavioral Health Training Program"} content={CBHTContent} />} />
        <Route path="/mh/bhpac" element={<Page iD={129} pageHeader={"Behavioral Health Planning and Advisory Council"} content={BHPACContent} />} />
        <Route path="/mh/bhpac-about" element={<Page iD={107} pageHeader={"About the Planning Council"} content={BHPACAboutContent} />} />
        <Route path="/mh/bhpac-summaries" element={<Page iD={131} pageHeader={"Behavioral Health Planning and Advisory Council"} content={BHPACSummariesContent} />} />
        <Route path="/mh/bhpac-bg" element={<Page iD={130} pageHeader={"Behavioral Health Planning and Advisory Council"} content={BHPACBGContent} />} />
        <Route path="/mh/soca" element={<Page iD={423} pageHeader={"System of Care Academy"} content={sOCA_Content} />} />
        <Route path="/mh/impact-video" element={<Page iD={402} pageHeader={"Kentucky IMPACT"} content={impactVideo_Content} />} />
        <Route path="/mh/act-principles" element={<Page iD={48} pageHeader={"Principles of Assertive Community Treatment"} content={actPrinciplesContent} />} />
        <Route path="/mh/aot" element={<Page iD={482} pageHeader={"Assisted Outpatient Treatment "} content={aOTContent} />} />
        

        {/* // ================================ Developmental and Intellectual Disabilities  =================================================== */}
        {/* DDID */}
        <Route path="/ddid" element={<Page iD={267} pageHeader={"Developmental and Intellectual Disabilities"} content={dDIDHome_Content} />} />
        <Route path="/ddid/csb" element={<Page iD={9} pageHeader={"Community Supports Branch"} content={cSB_Content} />} />
        {/* <Route path="/ddid/csb-services" element={<CSBServices />} /> */}
        <Route path="/ddid/scl" element={<Page iD={8} pageHeader={"Waiver Branch"} content={sCL_Content} />} />
        <Route path="/ddid/clinical" element={<Page iD={434} pageHeader={"Clinical Services Branch"} content={clinical_Content} />} />
        <Route path="/ddid/scl-training" element={<Page iD={306} pageHeader={"Supports for Community Living Training and Resources"} content={sCLTraining_Content} />} />
        <Route path="/ddid/scl-newprovider" element={<Page iD={29} pageHeader={"SCL Providers"} content={sCLNewProvider_Content} />} />
        <Route path="/ddid/hrcbic" element={<Page iD={768} pageHeader={"Human Rights and Behavior Intervention Committees"} content={hRCBIC_Content} />} />
        <Route path="/ddid/cm-training" element={<Page iD={293} pageHeader={"Case Management Training"} content={cMTraining_Content} />} />
        <Route path="/ddid/commission" element={<Page iD={235} pageHeader={"Commission"} content={commission_Content} />} />
        <Route path="/ddid/commission-documents" element={<Page iD={310} pageHeader={"Kentucky Commission on Services and Supports for Individuals with Intellectual or Other Developmental Disabilities"} content={commissionDocuments_Content} />} />
        <Route path="/ddid/scl-forms-certification" element={<Page iD={299} pageHeader={"Supports for Community Living Waiver Branch"} content={sCLFormsCertification_Content} />} />
        <Route path="/ddid/scl-forms-cm" element={<Page iD={298} pageHeader={"Supports for Community Living Waiver Branch"} content={sCLFormsCM_Content} />} />
        <Route path="/ddid/scl-forms-employment" element={<Page iD={373} pageHeader={"Supports for Community Living Waiver Branch"} content={sCLFormsEmployment_Content} />} />
        <Route path="/ddid/scl-forms-general" element={<Page iD={300} pageHeader={"Supports for Community Living Waiver Branch"} content={sCLFormsGeneral_Content} />} />
        <Route path="/ddid/scl-forms-incident" element={<Page iD={301} pageHeader={"Supports for Community Living Waiver Branch"} content={sCLFormsIncident_Content} />} />
        <Route path="/ddid/scl-forms-risk" element={<Page iD={302} pageHeader={"Supports for Community Living Waiver Branch"} content={sCLFormsRisk_Content} />} />
        <Route path="/ddid/scl-forms" element={<Page iD={297} pageHeader={"Supports for Community Living Waiver Branch"} content={sCLForms_Content} />} />
        <Route path="/ddid/scl-qa-submit" element={<Page iD={394} pageHeader={"SCL Questions and Answers"} content={sCLQASubmit_Content} />} />
        <Route path="/ddid/scl-regulations" element={<Page iD={361} pageHeader={"Supports for Community Living Waiver Branch"} content={sCLRegulations_Content} />} />
        <Route path="/ddid/scl-services" element={<Page iD={304} pageHeader={"Supports for Community Living Waiver Branch"} content={sCLServices_Content} />} />
        <Route path="/ddid/sgf-forms" element={<Page iD={307} pageHeader={"Supports for Community Living Waiver Branch"} content={sGFForms_Content} />} />
        <Route path="/ddid/im" element={<Page iD={483} pageHeader={"Incident Management Trainings"} content={iM_Content} />} />
        <Route path="/ddid/recordings" element={<Page iD={306} pageHeader={"DDID Recordings (webinars, presentations, training) "} content={recordingsContent} />} />

        {/* // ================================ DPI: Division of Program Integrity=================================================== */}
        {/* DPI */}
        <Route path="/dpi" element={<Page iD={419} pageHeader={"Program Integrity"} content={dPIHome_Content} />} />
        <Route path="/dpi/dui" element={<Page iD={467} pageHeader={"Driving Under the Influence Program"} content={dUI_Content} />} />
        <Route path="/dpi/opioid" element={<Page iD={155} pageHeader={"Medications for Opioid Use Disorder"} content={opiate_Content} />} />
        <Route path="/dpi/cap" element={<Page iD={412} pageHeader={"Curriculum Approval Processes"} content={cAP_Content} />} />
        <Route path="/dpi/tris" element={<Page iD={474} pageHeader={"DBHDID Training Records Information System"} content={tRIS_Content} />} />
        <Route path="/dpi/dui-training" element={<Page iD={459} pageHeader={"Driving Under the Influence Program"} content={dUITraining_Content} />} />
        <Route path="/dpi/dui-forms" element={<Page iD={469} pageHeader={"Driving Under the Influence Program"} content={dUIForms_Content} />} />
        <Route path="/dpi/dui-faq" element={<Page iD={460} pageHeader={"Driving Under the Influence Program"} content={dUIFAQ_Content} />} />
        {/* // ===================================================================================================================== */}
        {/* Facilites */}
        <Route path="/facilities" element={<Page iD={311} pageHeader={"Facilities"} content={facilitesHome_Content} />} />
        <Route path="/facilities/datareports" element={<Page iD={447} pageHeader={"Facility Data Reports"} content={dataReportsFacility_Content} />} />
        <Route path="/facilities/pdrates" element={<Page iD={433} pageHeader={"Facility Data Reports"} content={pDRates_Content} />} />
        <Route path="/facilities/procedures" element={<Page iD={313} pageHeader={"Procedures"} content={procedures_Content} />} />
        {/* Error Page */}
        <Route path="/*" element={<Error />} />
        {/* Test Page */}
        {/* // ================================================================================================== */}
        {/* <Route path="/test" element={<TestPage />} /> */}
      </Routes>
      {/* // ================================================================================================== */}
    </>
  )
}




export default App;
