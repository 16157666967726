// Libs:
import React from 'react';
import { useEffect } from 'react';
// css:
import "../assets/styles/pages/style.css"
// Header
import DepartmentHeader from '../components/DepartmentHeader';
// Layout
import IBITable from '../components/IBI_ReactTools/IBITable';
import Navbar from '../layouts/Navbar';
import Breadcrumbs from '../components/buttons/Breadcrumbs';
import Footer from '../layouts/Footer';
import currentPageStore from '../utils/stores/currentPageStore';

function Crisisnos({pageHeader}) {

    const setCurrentPage = currentPageStore((state) => state.setCurrentPage);

    useEffect(() => {
        setCurrentPage(pageHeader);
        // console.log(pageHeader)
    }, [pageHeader])

    const endpointKey = "crisisnos";
    // const exampleColumn = {
    //     display: "Display",
    //     order: 1,
    //     property: "propertyOne"
    // }
    const columns = [
        {
            display: "CMHC County",
            order: 10,
            property: 'regionString'
        },
        {
            display: "CMHC Region # - CMHC Name \n CMHC Website",
            order: 20,
            property: "RegionNameAndWebsite"
        },
        {
            //old display title: "Administrative Office Number \n 24-Hour Crisis Phone Number \n TTY Crisis Phone Number"
            display: " \n 24-Hour Crisis Phone Number \n TTY Crisis Phone Number",
            order: 30,
            property: "officeNumber"
        },
        {
            display: "Hospital Name \n Hospital Phone",
            order: 40,
            property: "hospitalNameAndPhone"
        },
    ]
    const processor = (data) => {

        data.forEach(element => {
            element.regionString = element.county + ": " + element.countyPhone;
            element.RegionNameAndWebsite = element.regionNumber + " " + element.regionName + "\n " +
                " " + element.regionUrl;

            element.officeNumber = " " +/*element.phone + */ element.crisisLine;
            element.hospitalNameAndPhone = element.hospital + " \n" + element.hospitalPhone;
        });
    }
    const search = (item, value) => {
        const hasRegionString = Object.hasOwn(item, "regionString") && item.regionString != null;
        const hasHospital = Object.hasOwn(item, "hospital") && item.hospital != null;
        if (item != null && hasRegionString && hasHospital && value != null) {
            return item.regionString.toLowerCase().includes(value.toLowerCase()) || item.hospital.toLowerCase().includes(value.toLowerCase());
        }
        return false;
    }
    return (
        <>

            <Navbar />
            <div className="central-nav-container wrapper">
            <Breadcrumbs />
                <div className="" id="bodyContent">
                    <div>
                        <div className=''>
                            <DepartmentHeader header="Community Mental Health Centers (CMHCs) and Adult State-Operated or State-Contracted Psychiatric Hospitals Alphabetically by County" />
                            <div class="row">
                                <div className='bodyText'>
                                    <b style={{ color: "red" }}>Please note: If you think you may have a medical or life-threatening behavioral health emergency or your condition becomes life-threatening, please call 911 or go to the nearest hospital immediately. For all other behavioral health crises, please call 988 or the 24-Hour Crisis Number listed below for your county.</b>
                                    <br /> <br />
                                    <b>*Individuals who are Deaf or Hard of Hearing are encouraged to call through Video Relay Services (VRS) amplified or captioned phones, or TTY Relay (7-1-1).</b>
                                    <br /> <br />
                                    <div className="subTitle">
                                        <div className='crisis'>
                                            <IBITable endpointKey={endpointKey} columns={columns} dataProcessingAction={processor} searchPredicate={search} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};
export default Crisisnos;