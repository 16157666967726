//Using the react library
import React from "react";
//Using the react library:
import { useEffect, useState } from "react";
//API Middle man
import useApi from "../utils/UseAPI";
// React loading spinner
import Spinner from 'react-bootstrap/Spinner';
import SanitizeHref from "../utils/SanitizeHref";

function PBForms() {

    const { error, processing, getRequest } = useApi();
    const [allForms, setAllForms] = useState([]);

    useEffect(() => {
        const fetchLinks = async () => {
            const [data, status] = await getRequest(null, "form/dpr-pb/pb?sfy=2024");
            if (status == 200) {
                setAllForms(data.data.pb);
                console.log(data.data.pb);
            }
            else {
                console.log(error);
            }
        };
        fetchLinks();
    }, []);     

    return (
        <>
            <div className="">
                <br />
                <h2 id="All Forms By Number">All Forms By Number</h2>
                <br />
                <div className="row">
                    <div className="col-md-6 col-md-3">
                        <ul className="no-bullet">
                            {processing ? <Spinner animation="border" variant="success" /> : allForms.map((data, index) => {
                                return (
                                    <li className="quick-links-body">
                                        <a key={index} href={SanitizeHref("documents/cmhc/pb/"+ data.formName+data.formExtention)}>{data.formName}</a>
                                    </li>
                                )
                            })}
                        </ul>           
                    </div>
                </div>
            </div>
        </>
    );
}

export default PBForms;