// libs:
import React from 'react';
import "../assets/styles/pages/style.css"
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom"

// Link to where I got the code: https://v5.reactrouter.com/web/guides/scroll-restoration
function ScrollToTop() {
    const [backToTopButton, setBackToTopButton] = useState(false);
    const { pathname } = useLocation();
    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.scrollY > 100) {
                setBackToTopButton(true)
            } else {
                setBackToTopButton(false)
            }
        })
    }, [])

    const scrollUp = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    }
    return (
        <>
            <div className='container text-center'>
                {backToTopButton && (<i className='fa fa-chevron-up up' onClick={scrollUp}><br/><div className='scroll-text'>Scroll To Top</div></i>)}
            </div>
        </>
    )

}

export default ScrollToTop;