//Using the react library
import React from "react";
//Using the react library:
import { useState } from "react";

import SCLServiceData from "../data/SCLServiceData.json"

function SCLServiceList() {

    const [selected, setSelected] = useState(null);

    return (
        <>
            <div className="col-md-4 col-md-3">
                {/* Return List */}
                <select className="form-select" onChange={(e) => {
                    // console.log(e.target.value);
                    // console.log(contentCounties);
                    const c = SCLServiceData.sCLService?.find((x) => x.id == e.target.value)

                    setSelected(c)
                    console.log(selected)
                }}>
                    <option>Select a Service</option>

                    {
                        SCLServiceData.sCLService.map((data, index) => {
                            return (
                                <option key={index} value={data.id} className="form-select"
                                >{data.title}</option>
                            )
                        })
                    }
                </select>

            </div>
            {selected ?
                <>
                    <br />
                    <br />

                    <b>{selected.title}. </b>

                    <br />
                    <br />
                    {selected.para}
                    <p>{selected.level1}</p>
                    <p>{selected.level2}</p>
                    <p>{selected.level3}</p>
                </>
                : null}
        </>

    );
}

export default SCLServiceList;